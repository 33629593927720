import {VehicleDetailsModel} from '@api/models/vehiclesModels';
import Button, {ButtonVariant} from '@components/Button/Button';
import Modal from '@components/Modal/Modal';
import React, {useCallback} from 'react';
import styles from './StatusModal.module.scss';
import {useDispatch} from 'react-redux';
import {loadingAction} from '@actions/appActions';
import {vehicleVisibilityRequest} from '@api/vehicles';
import { toast } from 'react-toastify';
import { changeVehicleVisibility } from '@actions/vehiclesPageActions';

interface StatusModalProp {
	vehicle: VehicleDetailsModel | false;
	closeHandler: () => void;
}

const StatusModal = (props: StatusModalProp) => {
	const {vehicle, closeHandler} = props;

	const dispatch = useDispatch();

	const changeVisibility = useCallback(async () => {
		if (vehicle === false) return toast.error('Nie odnaleziono id pojazdu!');
		dispatch(loadingAction(true));
		await vehicleVisibilityRequest(vehicle.id).then(() => dispatch(changeVehicleVisibility(vehicle.id)));
    closeHandler()
		dispatch(loadingAction(false));
	}, [closeHandler, dispatch, vehicle]);

	if (vehicle === false) return <></>;

	return (
		<Modal
			title={`Czy chcesz ${vehicle.isHidden ? 'aktywować' : 'ukryć'} pojazd ${
				vehicle.brand
			} ${vehicle.model} ${vehicle.bodyType} ${vehicle.cabinType ?? ''} ${
				vehicle.chassis
			}?`}
			closeHandler={closeHandler}>
			<div className={styles.buttons}>
				<Button
					type='button'
					onClick={closeHandler}
					variant={ButtonVariant.OutlineWithBorder}>
					Anuluj
				</Button>
				<Button type='button' onClick={changeVisibility}>
					Zatwierdź
				</Button>
			</div>
		</Modal>
	);
};

export default StatusModal;
