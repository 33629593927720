// Components
import Table from '@components/Table/Table';
import Container from '@components/Container/Container';
import {ReactComponent as Trash} from '@assets/icons/trash.svg';
// Types
import {Tag} from '@commonTypes/tags';
import {useDispatch, useSelector} from 'react-redux';
import {useEffect, useMemo, useState} from 'react';
import Button, {ButtonTag, ButtonVariant} from '@components/Button/Button';
import {
	getVehiclesPageMaxPage,
	getVehiclesPageVehicles,
} from '@selectors/vehiclesPageSelectors';
import {getVehiclesRequest} from '@api/vehicles';
import Pagination from '@components/Pagination/Pagination';
import Filters from '@components/Filters/Filters';
import {getBrandsRequest} from '@api/brands';
import {BrandModel} from '@api/models/brandsModels';
import {loadingAction} from '@actions/appActions';
import {getBodiesRequset} from '@api/body';
import {BodyModel} from '@api/models/bodyModels';
import StatusModal from '../StatusModal/StatusModal';
import { VehicleDetailsModel } from '@api/models/vehiclesModels';

interface VehiclesListProps {
	openModal: (id: number) => void;
}

const VehiclesList = (props: VehiclesListProps) => {
	const {openModal} = props;

	const disptach = useDispatch();
	const [allBrands, setAllBrands] = useState<BrandModel[]>([]);
	const [allBodies, setAllBodies] = useState<BodyModel[]>([]);
	const [modal, setModal] = useState<VehicleDetailsModel | false>(false);

	const vehicles = useSelector(getVehiclesPageVehicles);

	const tableElements = useMemo(
		() =>
			vehicles?.map((item) => {
				return {
					id: item.id,
					brand: item.brand,
					model: item.model,
					bodyType: item.bodyType,
					cabin: item.cabinType,
					chassis: item.chassis,
					status: (
						<Button onClick={() => setModal(item)} variant={ButtonVariant.Outline}>
							{item.isHidden ? 'Aktywuj' : 'Ukryj'}
						</Button>
					),
					edit: (
						<Button
							as={ButtonTag.InternalLink}
							href={`/vehicles/add?vehicleId=${item.id}`}
							variant={ButtonVariant.Outline}>
							Edytuj
						</Button>
					),
					delete: (
						<button
							type='button'
							className='button-delete'
							onClick={() => {
								openModal(item.id);
							}}>
							<Trash />
						</button>
					),
				};
			}),
		[vehicles, openModal]
	);

	useEffect(() => {
		(async () => {
			disptach(loadingAction(true));
			await getBrandsRequest().then((res) => setAllBrands(res));
			await getBodiesRequset().then((res) => setAllBodies(res));
			disptach(loadingAction(false));
		})();
	}, [disptach]);

	return (
	<>
		<Container as={Tag.Section}>
			<Filters
				filters={[
					{
						name: 'brandIds',
						label: 'Marka',
						options: allBrands.map((brand) => ({
							value: `${brand.id}`,
							name: brand.name,
						})),
					},
					{
						name: 'bodyTypeIds',
						label: 'Zabudowa',
						options: allBodies.map((body) => ({
							value: `${body.id}`,
							name: body.name,
						})),
					},
				]}
			/>
			<Table
				headers={[
					{text: 'ID', sortName: 'id'},
					{text: 'Marka', sortName: 'brand'},
					{text: 'Model', sortName: 'model'},
					{text: 'Zabudowa', sortName: 'body'},
					{text: 'Kabina', sortName: 'cabin'},
					{text: 'Podwozie', sortName: 'chassis'},
					{text: 'Status'},
					{text: 'Edytuj'},
					{text: 'Usuń'},
				]}
				elements={tableElements ?? []}
				groups={[
					{width: '5%'},
					{width: '10%'},
					{width: '10%'},
					{width: '10%'},
					{width: '10%'},
					{width: '10%'},
					{width: '2.5%'},
					{width: '2.5%'},
					{width: '5%'},
				]}
			/>
			<Pagination
				request={getVehiclesRequest}
				getMaxPages={getVehiclesPageMaxPage}
			/>
		</Container>
		<StatusModal vehicle={modal} closeHandler={() => setModal(false)}/>
	</>
	);
};

export default VehiclesList;
