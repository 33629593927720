export interface PropertyDimensionRangeModel {
	attributeId: number;
	dimensionType: string;
	dimensionTypeId: DimensionTypes;
	isAttributeRequired: boolean;
	maxValue: number;
	minValue: number;
}

export interface PropertyModel {
	id: number;
	name: string;
	categoryId: number;
	categoryName: string;
	price: number;
	weight: number;
	description: string;
	isEuroPrice: boolean;
	isHidden: boolean;
	shortDescription: string;
	imageUrl: string;
	dimensionRange: PropertyDimensionRangeModel | null;
	priority: number;
}

export interface GetPropertiesResponse {
	attributes: PropertyModel[];
	pageIndex: number;
	totalPages: number;
	itemsCount: number;
}

export enum DimensionTypes {
	Length = 1,
	Height = 2,
	Width = 3,
}

export interface CommonPropertyData {
	name: string;
	categoryId: number;
	price: number;
	weight: number;
	description: string;
	shortDescription: string;
	isEuroPrice: boolean;
	isHidden: boolean;
	priority: number;
	dimensionTypeId?: DimensionTypes | string;
	minValue?: number | string;
	maxValue?: number | string;
	isAttributeRequired?: boolean | string; // for dimension
	image: File | undefined;
}

export interface AddPropertyData extends CommonPropertyData {
	bodyWorksIds: number[];
}

export interface EditPropertyData extends CommonPropertyData {}

export interface AddPropertyResponse {
	statusCode: number;
	value: PropertyModel;
}

export interface PropertyVehicleModel {
	id: number;
	attributeId: number;
	attributeName: string;
	mark: string;
	model: string;
	bodyType: string;
	chassis: string;
	price: number | null;
	weight: number | null;
	isEuroPrice: boolean;
	imageUrl: string;
	cabinType: number | null;
}

export interface AddPropertyVehicleRequest {
	vehicleIds: number[];
	attributeId: number;
	price: number;
	weight: number;
}

export interface EditPropertyVehicleRequest {
	id: number;
	image: File | undefined;
	price: number;
	weight: number;
}

export interface ExcludedPropertyModel {
	dependentAttributeName: string;
	dependentAttributteId: number;
	id: number;
	imageUrl: string | null;
}

export interface PropertyAvailableVehicleModel {
	id: number;
	brand: string;
	cabin: string;
	bodyType: string;
	chassis: string;
	model: string;
	price: number;
}

export interface RelationshipsRequestData {
	attributeId: number;
	excludedAttributeIds: number[];
	dependentAttributeIds: number[];
}
