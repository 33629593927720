// Components
import AppHeader from '@components/AppHeader/AppHeader';
import UsersList from './components/UsersList/UsersList';
// Styles
import styles from './Users.module.scss';
import {useCallback, useMemo, useState} from 'react';
import {ModalType} from '@commonTypes/main';
import ConfirmForm from './components/ConfirmForm/ConfirmForm';
import {UserModel} from '@api/models/authModels';
import Modal from '@components/Modal/Modal';
import {confirmUser, deleteUser, rejectUser} from '@api/auth';
import {useDispatch} from 'react-redux';
import {removeUser, confirmUser as confirmUserRedux} from '@actions/usersPageActions';
import ResetPasswordForm from './components/ResetPasswordForm/ResetPasswordForm';
import RejectForm from './components/RejectForm/RejectForm';

const Users = () => {
	const [modalOpened, setModalOpened] = useState<boolean>(false);
	const [modalType, setModalType] = useState<ModalType>(ModalType.ADD);
	const [currentUser, setCurrentUser] = useState<UserModel | undefined>(
		undefined
	);

	const dispatch = useDispatch();

	const toggleModal = (
		isOpened: boolean,
		modalType: ModalType,
		user?: UserModel
	) => {
		if (modalType !== ModalType.ADD && user?.id === undefined)
			throw new Error('You must provide userId!');
		setModalOpened(isOpened);
		setModalType(modalType);
		setCurrentUser(user);
	};

	const closeModal = () => {
		setModalOpened(false);
		setCurrentUser(undefined);
	};

	const confirmAccount = useCallback(async () => {
		if (!!!currentUser) throw new Error('You must provide user!');

		await confirmUser(currentUser.id).then((res) => {
			dispatch(confirmUserRedux(currentUser.id));
			closeModal();
		});
	}, [currentUser, dispatch]);

	const rejectAccount = useCallback(async () => {
		if (!!!currentUser) throw new Error('You must provide user!');

		if(currentUser.toConfirm){
			await rejectUser(currentUser.id).then((res) => {
				dispatch(removeUser(currentUser.id));
				closeModal();
			});
		}else{
			await deleteUser(currentUser.id).then((res) => {
				dispatch(removeUser(currentUser.id));
				closeModal();
			});
		}
	}, [currentUser, dispatch]);

	const modalView = useMemo(() => {
		switch (modalType) {
			case ModalType.ADD:
				return (
					currentUser && (
						<ConfirmForm
							user={currentUser}
							closeHandler={closeModal}
							submitHandler={confirmAccount}
						/>
					)
				);
			case ModalType.EDIT:
				return (
					currentUser && (
						<ResetPasswordForm
							user={currentUser}
							closeHandler={closeModal}
						/>
					)
				);
			case ModalType.DELETE:
				return (
					currentUser && (
						<RejectForm
							user={currentUser}
							closeHandler={closeModal}
							submitHandler={rejectAccount}
						/>
					)
				);
			default:
				throw new Error(`Unknown modal type: ${modalType}`);
		}
	}, [modalType, currentUser, confirmAccount, rejectAccount]);

	const modalTitle = useMemo(() => {
		switch (modalType) {
			case ModalType.ADD:
				return 'Aktywuj konto użytkownika.';
			case ModalType.EDIT:
				return 'Potwierdzono reset hasła.';
			case ModalType.DELETE:
				return 'Czy na pewno chcesz odrzucić użytkownika?';
			default:
				throw new Error(`Unknown modal type: ${modalType}`);
		}
	}, [modalType]);

	return (
		<>
			<AppHeader
				title='Zarządzanie nowymi użytkownikami'
				text='Zatwierdź nowo utworzone konta, aby nadać dostępy do panelu klienckiego nowym użytkownikom.'
				classes={styles.header}></AppHeader>
			<UsersList toggleModal={toggleModal} />
			{modalOpened && (
				<Modal title={modalTitle} closeHandler={closeModal}>
					{modalView}
				</Modal>
			)}
		</>
	);
};

export default Users;
