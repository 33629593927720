// Helpers
import {requestCreator} from '@helpers/requsetCreator';
import {
	AddVehicleRequsetData,
	GetVehiclesResponse,
	VehicleDetailsModel,
	VehicleImageRequestModel,
} from './models/vehiclesModels';
import {Dispatch} from 'react';
import {UnknownAction} from '@reduxjs/toolkit';
import {setVehicles} from '@actions/vehiclesPageActions';
import {getFilterParams} from '@helpers/getFilterParams';
// Types

export const addVehicleRequest = async (
	data: AddVehicleRequsetData
): Promise<any> =>
	await requestCreator<AddVehicleRequsetData, any>(
		'PUT',
		`/vechicle/carBody-add`,
		data,
		undefined,
		`Pojazd został dodany!`
	);

export const editVehicleRequest = async (
	id: number,
	data: AddVehicleRequsetData
): Promise<any> =>
	await requestCreator<AddVehicleRequsetData, any>(
		'POST',
		`/vechicle/carBody-edit?id=${id}`,
		data,
		undefined,
		`Pojazd został zmodyfikowany!`
	);

export const getVehiclesRequest = async (
	dispatch: Dispatch<UnknownAction> | undefined,
	pageIndex: number,
	pageSize: number,
	filters?: {brandIds?: number; bodyTypeIds?: number}
): Promise<GetVehiclesResponse> => {
	let url = `/vechicle/carBodies?pageIndex=${pageIndex}&pageSize=${pageSize}`;

	const res = await requestCreator<FormData, GetVehiclesResponse>(
		'GET',
		getFilterParams(filters, url)
	);

	dispatch && dispatch(setVehicles(res));

	return res;
};

export const getVehicleRequest = async (
	id: number
): Promise<VehicleDetailsModel[]> =>
	await requestCreator<FormData, VehicleDetailsModel[]>(
		'GET',
		`/vechicle/carBody?id=${id}`
	);

export const deleteVehicleRequest = async (id: number): Promise<string> =>
	await requestCreator<FormData, string>('DELETE', `/vechicle/carBody?id=${id}`);

export const vehicleVisibilityRequest = async (id: number): Promise<any> =>
	await requestCreator<AddVehicleRequsetData, any>(
		'POST',
		`/vechicle/isHidden-change?vechicleId=${id}`,
		undefined,
		undefined,
		`Status pojazd został zmieniony!`
	);

export const vehicleImageRequest = async (
	id: number,
	data: VehicleImageRequestModel
): Promise<any> => {
	const formData = new FormData();

	formData.append('image', (data.image as Blob) ?? '');

	return await requestCreator<FormData, any>(
		'POST',
		`/vechicle/image-edit?id=${id}`,
		formData,
		{
			'Content-Type': 'multipart/form-data',
		}
	);
};

export const vehicleBodyImageRequest = async (
	id: number,
	data: VehicleImageRequestModel
): Promise<any> => {
	const formData = new FormData();

	formData.append('image', (data.image as Blob) ?? '');

	return await requestCreator<FormData, any>(
		'POST',
		`/vechicle/body-image-edit?id=${id}`,
		formData,
		{
			'Content-Type': 'multipart/form-data',
		}
	);
};
