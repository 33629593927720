import {GetVehiclesResponse, VehicleDetailsModel} from '@api/models/vehiclesModels';

export interface VehiclesPage {
	vehicles: VehicleDetailsModel[] | null;
	currentPage: number;
	maxPage: number;
}

export enum VehiclesPageActions {
	SET_VEHICLES = 'SET_VEHICLES',
	DELETE_VEHICLE = 'DELETE_VEHICLE',
	SET_VEHICLES_CURRENT_PAGE = 'SET_VEHICLES_CURRENT_PAGE',
	SET_VEHICLES_MAX_PAGE = 'SET_VEHICLES_MAX_PAGE',
	SET_VEHICLE_VISIBILITY = 'SET_VEHICLE_VISIBILITY',
}

export const setVehicles = (vehicles: GetVehiclesResponse) => ({
	type: VehiclesPageActions.SET_VEHICLES,
	payload: vehicles,
});

export const deleteVehicle = (id: number) => ({
	type: VehiclesPageActions.DELETE_VEHICLE,
	payload: id,
});

export const setVehiclesCurrentPage = (currentPage: number) => ({
	type: VehiclesPageActions.SET_VEHICLES_CURRENT_PAGE,
	payload: currentPage,
});

export const setVehiclesMaxPage = (maxPage: number) => ({
	type: VehiclesPageActions.SET_VEHICLES_MAX_PAGE,
	payload: maxPage,
});

export const changeVehicleVisibility = (id: number) => ({
	type: VehiclesPageActions.SET_VEHICLE_VISIBILITY,
	payload: id,
});
