// Actions
import {UsersPageActions} from '@actions/usersPageActions';
// Types
import {GetUsersResponse, UserModel} from '@api/models/authModels';
import {UsersPage} from '@actions/usersPageActions';

const initialState: UsersPage = {
	users: null,
	currentPage: 1,
	maxPage: 1,
};

export const usersPageReducer = (
	state = initialState,
	action: {
		type: UsersPageActions;
		payload: GetUsersResponse | UserModel[] | string | number;
	}
): UsersPage => {
	switch (action.type) {
		case UsersPageActions.SET_USERS:
			const data = action.payload as GetUsersResponse
			return {...state, users: data.userList, maxPage: data.totalPages, currentPage: data.itemsCount};
		case UsersPageActions.CONFIRM_USER:
			return {
				...state,
				users: state.users?.map(
					(item) => item.id === (action.payload as string) ? {...item, toConfirm: false} : item
				) as UserModel[],
			};
		case UsersPageActions.REMOVE_USER:
			return {
				...state,
				users: state.users?.filter(
					(item) => item.id !== (action.payload as string)
				) as UserModel[],
			};
		case UsersPageActions.SET_USERS_CURRENT_PAGE:
			return {...state, currentPage: action.payload as number};
		case UsersPageActions.SET_USERS_MAX_PAGE:
			return {...state, maxPage: action.payload as number};
		default:
			return state;
	}
};
