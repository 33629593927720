// Components
import Table from '@components/Table/Table';
import Container from '@components/Container/Container';
import {ReactComponent as Trash} from '@assets/icons/trash.svg';
// Types
import {Tag} from '@commonTypes/tags';
import {useSelector} from 'react-redux';
import {useMemo} from 'react';
import Button, {ButtonVariant} from '@components/Button/Button';
import {
	getUsersPageMaxPage,
	getUsersPageUsers,
} from '@selectors/usersPageSelectors';
import {getUsers} from '@api/auth';
import {ModalType} from '@commonTypes/main';
import {UserModel} from '@api/models/authModels';
import Pagination from '@components/Pagination/Pagination';

interface UsersListProps {
	toggleModal: (
		isOpened: boolean,
		modalType: ModalType,
		user?: UserModel
	) => void;
}

const UsersList = (props: UsersListProps) => {
	const {toggleModal} = props;

	const users = useSelector(getUsersPageUsers);

	const tableElements = useMemo(
		() =>
			users?.map((item) => {
				return {
					id: `${item.id.slice(0, 5)}...`,
					attention:
						item.askForPasswordReset || item.toConfirm ? 'Wymaga uwagi' : 'Aktywny',
					name: `${item.firstName} ${item.lastName}`,
					email: item.login,
					brand: item.brand,
					phone: item.phone,
					companyName: item.companyName,
					resetPassword: item.askForPasswordReset ? (
						<Button
							type='button'
							onClick={() => {
								toggleModal(true, ModalType.EDIT, item);
							}}
							variant={ButtonVariant.Outline}>
							Zatwierdź
						</Button>
					) : (
						<p style={{textAlign: 'center'}}>-</p>
					),
					edit:
						!item.askForPasswordReset && item.toConfirm ? (
							<Button
								type='button'
								onClick={() => {
									toggleModal(true, ModalType.ADD, item);
								}}
								variant={ButtonVariant.Outline}>
								Aktywuj
							</Button>
						) : (
							<p style={{textAlign: 'center'}}>-</p>
						),
					delete: (
						<button
							type='button'
							className='button-delete'
							onClick={() => {
								toggleModal(true, ModalType.DELETE, item);
							}}>
							<Trash />
						</button>
					),
				};
			}),
		[toggleModal, users]
	);

	return (
		<Container as={Tag.Section}>
			<Table
				headers={[
					{text: 'Id', sortName: 'id'},
					{text: 'Status', sortName: 'status'},
					{text: 'Nazwa użytkownika', sortName: 'name'},
					{text: 'E-mail', sortName: 'email'},
					{text: 'Marka', sortName: 'brand'},
					{text: 'Telefon', sortName: 'phone'},
					{text: 'Firma', sortName: 'company'},
					{text: 'Reset hasła'},
					{text: 'Aktywuj'},
					{text: 'Usuń'},
				]}
				elements={tableElements ?? []}
				groups={[
					{width: '5%'},
					{width: '20%', minWidth: 140},
					{width: '30%', minWidth: 225},
					{width: '10%', minWidth: 150},
					{width: '10%', minWidth: 125},
					{width: '5%', minWidth: 125},
					{width: '5%', minWidth: 125},
					{width: '5%', minWidth: 120},
					{width: '5%'},
					{width: '5%'},
				]}
			/>
			<Pagination request={getUsers} getMaxPages={getUsersPageMaxPage} />
		</Container>
	);
};

export default UsersList;
