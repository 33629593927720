// Actions
import {VehiclesPageActions} from '@actions/vehiclesPageActions';
// Types
import {VehiclesPage} from '@actions/vehiclesPageActions';
import {
	GetVehiclesResponse,
	VehicleDetailsModel,
} from '@api/models/vehiclesModels';

const initialState: VehiclesPage = {
	vehicles: null,
	currentPage: 1,
	maxPage: 1,
};

export const vehiclesPageReducer = (
	state = initialState,
	action: {
		type: VehiclesPageActions;
		payload: GetVehiclesResponse | VehicleDetailsModel | number;
	}
): VehiclesPage => {
	switch (action.type) {
		case VehiclesPageActions.SET_VEHICLES:
			const data = action.payload as GetVehiclesResponse;
			return {
				...state,
				vehicles: data.vechicles,
				maxPage: data.totalPages,
				currentPage: data.pageIndex,
			};
		case VehiclesPageActions.DELETE_VEHICLE:
			return {
				...state,
				vehicles: state.vehicles?.filter(
					(item) => item.id !== action.payload
				) as VehicleDetailsModel[],
			};
		case VehiclesPageActions.SET_VEHICLE_VISIBILITY:
			return {
				...state,
				vehicles: state.vehicles?.map((item) =>
					item.id === action.payload ? {...item, isHidden: !item.isHidden} : item
				) as VehicleDetailsModel[],
			};
		case VehiclesPageActions.SET_VEHICLES_CURRENT_PAGE:
			return {...state, currentPage: action.payload as number};
		case VehiclesPageActions.SET_VEHICLES_MAX_PAGE:
			return {...state, maxPage: action.payload as number};
		default:
			return state;
	}
};
