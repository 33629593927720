import {FormField, FormKeys} from '@commonTypes/main';
import {Title} from '@components/_Typography';
import {TitleTag, TitleVariant} from '@components/_Typography/Title/Title';
import React from 'react';
import {initialState} from '../VehicleAddForm/VehicleAddForm';
import Input from '@components/_FormElements/Input/Input';
import FileInput from '@components/_FormElements/FileInput/FileInput';
import Checkbox from '@components/_FormElements/Checkbox/Checkbox';
import styles from './VehicleAddFormDescription.module.scss';

interface VehicleAddFormDescriptionProps {
	form: FormKeys<typeof initialState>;
	updateFormHandler: (name: string, value: FormField) => void;
}

const VehicleAddFormDescription = (props: VehicleAddFormDescriptionProps) => {
	const {form, updateFormHandler} = props;

	return (
		<div>
			<Title tag={TitleTag.h3} variant={TitleVariant.standard}>
				6. Opisz pojazd
			</Title>
			<Input
				id='description'
				name='description'
				placeholder={'Opis pojazdu'}
				value={form.description.value}
				valueChangeHandler={updateFormHandler}
				label={'Opis pojazdu będzie wyświetlany w podsumowaniu wyceny'}
				as='textarea'
				required={form.description.required}
				errorMessage={form.description.errorMessage}
			/>
			<div className={styles.row}>
				<FileInput
					id='bodyImage'
					name='bodyImage'
					placeholder={'+ Dodaj plik'}
					label={'Zdjęcie pojazdu'}
					accept='image/*'
					required={form.bodyImage.required}
					value={form.bodyImage.value}
					errorMessage={form.bodyImage.errorMessage}
					valueChangeHandler={updateFormHandler}
				/>
				<FileInput
					id='image'
					name='image'
					placeholder={'+ Dodaj plik'}
					label={'Zdjęcie Dodatkowe'}
					accept='image/*'
					required={form.image.required}
					value={form.image.value}
					errorMessage={form.image.errorMessage}
					valueChangeHandler={updateFormHandler}
				/>
			</div>
			<br />
			<Checkbox
				id='isHidden'
				name='isHidden'
				label={
					form.isHidden.value
						? 'Pojazd ukryty w panelu klienckim.'
						: 'Pojazd widoczny w panelu klienckim..'
				}
				required={false}
				value={form.isHidden?.value}
				valueChangeHandler={updateFormHandler}
				toggler
			/>
		</div>
	);
};

export default VehicleAddFormDescription;
