// Libraries
import {FormEvent, useEffect, useMemo, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
// Componetns
import Input, {defaultInput} from '@components/_FormElements/Input/Input';
import Button, {ButtonVariant} from '@components/Button/Button';
import FileInput from '@components/_FormElements/FileInput/FileInput';
import Select from '@components/_FormElements/Select/Select';
// Helpers
import {validateForm} from '@helpers/validateForm';
// Api
import {getCategoryPageCategories} from '@selectors/categoryPageSelectors';
import {
	addPropertyRequest,
	editPropertyRequest,
	getPropertyRequest,
} from '@api/properties';
// Redux
import {addProperty, editProperty} from '@actions/propertiesPageActions';
// Types
import {FormField, FormKeys} from '@commonTypes/main';
// Styles
import styles from './AddForm.module.scss';
import {getCategoriesRequest} from '@api/categories';
import {getFileFromUrl} from '@helpers/getFileFromUrl';
import {getBodiesRequset} from '@api/body';
import {setBodies} from '@actions/bodyPageActions';
import {getBodyPageBodies} from '@selectors/bodyPageSelectors';
import Checkbox from '@components/_FormElements/Checkbox/Checkbox';
import {loadingAction} from '@actions/appActions';
import {DimensionTypes} from '@api/models/propertiesModels';
import classNames from 'classnames';
import {toast} from 'react-toastify';

interface AddFormProps {
	id?: number;
	closeHandler: () => void;
}

const initialState = {
	name: {...defaultInput, required: true},
	weight: {...defaultInput, required: true},
	isEuroPrice: {...defaultInput, value: false},
	isHidden: {...defaultInput, value: false},
	price: {...defaultInput, required: true},
	description: {...defaultInput, required: true},
	category: {
		...defaultInput,
		required: true,
		value: 0,
	},
	bodies: {
		...defaultInput,
		required: true,
		value: [] as number[],
	},
	file: {...defaultInput, value: undefined as undefined | File, required: false},
	isAttributeRequired: {...defaultInput, value: false},
	isWidth: {...defaultInput, value: false},
	isHeight: {...defaultInput, value: false},
	isLength: {...defaultInput, value: false},
	minValue: {...defaultInput, value: ''},
	maxValue: {...defaultInput, value: ''},
	priority: {...defaultInput, value: '0', required: true},
};

const AddForm = (props: AddFormProps) => {
	const {id, closeHandler} = props;

	const [form, setForm] = useState<FormKeys<typeof initialState>>(initialState);
	const categories = useSelector(getCategoryPageCategories);
	const bodies = useSelector(getBodyPageBodies);

	const dispatch = useDispatch();

	const updateFormHandler = (name: string, value: FormField) => {
		if (name === 'isWidth' && value.value === true) {
			setForm({
				...form,
				[name]: value,
				isLength: {...form.isLength, value: !value.value},
				isHeight: {...form.isHeight, value: !value.value},
			});
		} else if (name === 'isLength' && value.value === true) {
			setForm({
				...form,
				[name]: value,
				isWidth: {...form.isWidth, value: !value.value},
				isHeight: {...form.isHeight, value: !value.value},
			});
		} else if (name === 'isHeight' && value.value === true) {
			setForm({
				...form,
				[name]: value,
				isLength: {...form.isLength, value: !value.value},
				isWidth: {...form.isWidth, value: !value.value},
			});
		} else {
			setForm({...form, [name]: value});
		}
	};

	const submitHandler = async (e: FormEvent) => {
		e.preventDefault();
		const isError = id
			? validateForm(
					{
						name: form.name,
						category: form.category,
						price: form.price,
						weight: form.weight,
						description: form.description,
						file: form.file,
						isEuroPrice: form.isEuroPrice,
						isHidden: form.isHidden,
						isAttributeRequired: form.isAttributeRequired,
						isWidth: form.isWidth,
						isHeight: form.isHeight,
						isLength: form.isLength,
						minValue: form.minValue,
						maxValue: form.maxValue,
						priority: form.priority,
					},
					setForm
			  )
			: validateForm(form, setForm);

		if (isError) return;

		dispatch(loadingAction(true));

		const dimensionTypeId = form.isHeight.value
			? DimensionTypes.Height
			: form.isLength.value
			? DimensionTypes.Length
			: form.isWidth.value
			? DimensionTypes.Width
			: '';

		const formData = {
			name: form.name.value,
			categoryId: form.category.value,
			price: form.price.value,
			weight: form.weight.value,
			description: form.description.value,
			shortDescription: '',
			image: form.file.value,
			isEuroPrice: form.isEuroPrice.value,
			isHidden: form.isHidden.value,
			priority: +form.priority.value,
			dimensionTypeId,
			minValue: isSizeDependency ? +form.minValue.value : '',
			maxValue: isSizeDependency ? +form.maxValue.value : '',
			isAttributeRequired: isSizeDependency ? form.isAttributeRequired.value : '',
		};

		if (isSizeDependency) {
			if (+form.minValue.value > +form.maxValue.value) {
				dispatch(loadingAction(false));
				return toast.warning('Zakres wymiarów jest nieprawidłowy.');
			}
		}

		if (id) {
			await editPropertyRequest(id, formData).then((res) => {
				dispatch(editProperty(res));
				closeHandler();
				setForm(initialState);
			});
		} else {
			await addPropertyRequest({
				...formData,
				bodyWorksIds: form.bodies.value,
			}).then((res) => {
				dispatch(addProperty(res.value));
				closeHandler();
				setForm(initialState);
			});
		}

		dispatch(loadingAction(false));
	};

	useEffect(() => {
		(async () => {
			await getCategoriesRequest(dispatch, 1, 999999);
			await getBodiesRequset().then((res) => dispatch(setBodies(res)));
		})();

		if (id === undefined) return;

		(async () => {
			dispatch(loadingAction(true));
			const editedProperty = await getPropertyRequest(id);
			const image = await getFileFromUrl(
				editedProperty.imageUrl,
				editedProperty.name
			);

			setForm({
				...form,
				name: {...form.name, value: editedProperty.name},
				category: {...form.category, value: editedProperty.categoryId},
				price: {...form.price, value: editedProperty.price},
				weight: {...form.weight, value: editedProperty.weight},
				description: {...form.description, value: editedProperty.description},
				isEuroPrice: {...form.isEuroPrice, value: editedProperty.isEuroPrice},
				isHidden: {...form.isHidden, value: editedProperty.isHidden},
				file: {...form.file, value: image},
				isAttributeRequired: {
					...form.isAttributeRequired,
					value: !!editedProperty.dimensionRange?.isAttributeRequired,
				},
				isHeight: {
					...form.isHeight,
					value:
						editedProperty.dimensionRange?.dimensionTypeId === DimensionTypes.Height,
				},
				isLength: {
					...form.isLength,
					value:
						editedProperty.dimensionRange?.dimensionTypeId === DimensionTypes.Length,
				},
				isWidth: {
					...form.isWidth,
					value:
						editedProperty.dimensionRange?.dimensionTypeId === DimensionTypes.Width,
				},
				minValue: {
					...form.minValue,
					value: `${editedProperty.dimensionRange?.minValue ?? ''}`,
				},
				maxValue: {
					...form.maxValue,
					value: `${editedProperty.dimensionRange?.maxValue ?? ''}`,
				},
				priority: {...form.priority, value: `${editedProperty.priority}`},
			});
			dispatch(loadingAction(false));
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	const isSizeDependency = useMemo(() => {
		return form.isHeight.value || form.isLength.value || form.isWidth.value;
	}, [form?.isHeight?.value, form?.isLength?.value, form?.isWidth?.value]);

	return (
		<form onSubmit={submitHandler}>
			<Input
				id='name'
				name='name'
				type='text'
				placeholder={'Wpisz nazwę'}
				label={'Nazwa cechy'}
				required={form.name.required}
				value={form.name.value}
				errorMessage={form.name.errorMessage}
				valueChangeHandler={updateFormHandler}
			/>
			<Select<number | undefined>
				id='category'
				name='category'
				label={''}
				value={form.category.value}
				errorMessage={form.category.errorMessage}
				valueChangeHandler={updateFormHandler}
				defaultOption={{value: 0, label: 'Kategoria'}}
				options={categories?.map((category) => ({
					value: category.id,
					label: category.name,
				}))}
				zIndex={99}
				required
			/>

			<div className={styles.metrics}>
				<div className={styles.metricsPrice}>
					<Checkbox
						id='isEuroPrice'
						name='isEuroPrice'
						label={`Cena w ${form.isEuroPrice?.value ? 'Euro' : 'Złotówkach'}`}
						required={false}
						value={form.isEuroPrice?.value}
						valueChangeHandler={updateFormHandler}
						toggler
					/>
					<Input
						id='price'
						name='price'
						type='text'
						placeholder={'Wpisz cenę'}
						label={'Podstawowa cena'}
						required={form.price.required}
						value={form.price.value}
						errorMessage={form.price.errorMessage}
						valueChangeHandler={updateFormHandler}
					/>
				</div>
				<Input
					id='weight'
					name='weight'
					type='text'
					placeholder={'Wpisz wagę'}
					label={'Podstawowa waga'}
					required={form.weight.required}
					value={form.weight.value}
					errorMessage={form.weight.errorMessage}
					valueChangeHandler={updateFormHandler}
				/>
			</div>
			{!!!id && (
				<Select<number | undefined>
					id='bodies'
					name='bodies'
					label={''}
					value={form.bodies.value}
					errorMessage={form.bodies.errorMessage}
					valueChangeHandler={updateFormHandler}
					defaultOption={{value: 0, label: 'Przypisz zabudowy'}}
					options={bodies?.map((body) => ({
						value: body.id,
						label: body.name,
					}))}
					multiselect
					required
					zIndex={98}
				/>
			)}
			<Input
				id='description'
				name='description'
				as='textarea'
				placeholder={'Opisz cechę'}
				label={'Opis'}
				required={form.description.required}
				value={form.description.value}
				errorMessage={form.description.errorMessage}
				valueChangeHandler={updateFormHandler}
			/>
			<FileInput
				id='file'
				name='file'
				placeholder={'+ Dodaj plik'}
				label={'Dodaj zdjęcie'}
				accept='image/*'
				required={form.file.required}
				value={form.file.value}
				errorMessage={form.file.errorMessage}
				valueChangeHandler={updateFormHandler}
			/>

			<Input
				id='priority'
				name='priority'
				type='text'
				placeholder={'Priorytet'}
				label={'Priorytet'}
				required={form.priority.required}
				value={form.priority.value}
				errorMessage={form.priority.errorMessage}
				valueChangeHandler={updateFormHandler}
			/>

			<div className={styles.hidden}>
				<Checkbox
					id='isHidden'
					name='isHidden'
					label={
						form.isHidden?.value
							? 'Dodanie tej cechy będzie możliwe tylko poprzez zależności.'
							: 'Standardowe przełączanie cechy.'
					}
					required={false}
					value={form.isHidden?.value}
					valueChangeHandler={updateFormHandler}
					toggler
				/>
			</div>
			<div className={styles.hidden}>
				<Checkbox
					id='isLength'
					name='isLength'
					label={'Uzależnione od długości'}
					required={false}
					value={form.isLength?.value}
					valueChangeHandler={updateFormHandler}
					toggler
				/>
			</div>
			<div className={styles.hidden}>
				<Checkbox
					id='isWidth'
					name='isWidth'
					label={'Uzależnione od szerokości'}
					required={false}
					value={form.isWidth?.value}
					valueChangeHandler={updateFormHandler}
					toggler
				/>
			</div>
			<div className={styles.hidden}>
				<Checkbox
					id='isHeight'
					name='isHeight'
					label={'Uzależnione od wysokości'}
					required={false}
					value={form.isHeight?.value}
					valueChangeHandler={updateFormHandler}
					toggler
				/>
			</div>
			{isSizeDependency && (
				<div className={classNames(styles.metrics, styles.hidden)}>
					<Input
						id='minValue'
						name='minValue'
						type='text'
						placeholder={'Od'}
						label={'Od'}
						required={form.minValue.required}
						value={form.minValue.value}
						errorMessage={form.minValue.errorMessage}
						valueChangeHandler={updateFormHandler}
					/>
					<Input
						id='maxValue'
						name='maxValue'
						type='text'
						placeholder={'Do'}
						label={'Do'}
						required={form.maxValue.required}
						value={form.maxValue.value}
						errorMessage={form.maxValue.errorMessage}
						valueChangeHandler={updateFormHandler}
					/>
				</div>
			)}
			{isSizeDependency && (
				<Checkbox
					id='isAttributeRequired'
					name='isAttributeRequired'
					label={
						form.isAttributeRequired?.value
							? 'Cecha wymagana dla danego zakresu'
							: 'Cecha dostępna dla danego zakresu'
					}
					required={false}
					value={form.isAttributeRequired?.value}
					valueChangeHandler={updateFormHandler}
					toggler
				/>
			)}
			<div className={styles.buttons}>
				<Button
					type='button'
					variant={ButtonVariant.Outline}
					onClick={closeHandler}>
					Anuluj
				</Button>
				<Button type='submit' variant={ButtonVariant.Primary}>
					Zapisz
				</Button>
			</div>
		</form>
	);
};

export default AddForm;
