import React from 'react';
// Components
import Button, {ButtonVariant} from '@components/Button/Button';
// Styles
import styles from './RejectForm.module.scss';
import {UserModel} from '@api/models/authModels';
import {Text} from '@components/_Typography';
import { FontColors } from '@commonTypes/main';

interface RejectFormProps {
	user: UserModel;
	closeHandler: () => void;
	submitHandler: () => void;
}

const RejectForm = (props: RejectFormProps) => {
	const {user, closeHandler, submitHandler} = props;

	return (
		<>
			<Text color={FontColors.danger}>
				Konto użytkownika{' '}
				<strong>
					{user.firstName} {user.lastName}
				</strong>{' '}
				zostanie odrzucone. Nie otrzyma on możliwość zalogowania się do panelu
				klienta.
			</Text>
			<div className={styles.buttons}>
				<Button
					type='button'
					variant={ButtonVariant.OutlineWithBorder}
					onClick={closeHandler}>
					Anuluj
				</Button>
				<Button variant={ButtonVariant.Danger} type='button' onClick={submitHandler}>
					Usuń konto
				</Button>
			</div>
		</>
	);
};

export default RejectForm;
