// Libraries
import {AxiosRequestConfig, AxiosResponse} from 'axios';
import {toast} from 'react-toastify';
// Configurations
import {api} from '../api/_config';
import store from '../redux/reduxStore';
import {logOutAction} from '@actions/authActions';
// Helpers
import {getToken} from '@helpers/authorization';

interface ApiResponse<T> extends AxiosResponse {
	data: T;
}

export const requestCreator = async <P, R>(
	method: 'GET' | 'POST' | 'PUT' | 'DELETE',
	url: string,
	params?: P,
	headers?: any,
	successMessage?: string
): Promise<R> => {
	try {
		const config: AxiosRequestConfig = {
			method,
			url,
			headers: {
				...api.defaults.headers,
				...headers,
				Authorization: `Bearer ${getToken()}`,
			},
		};

		if (method === 'GET') {
			config.params = params as any;
		} else {
			config.data = params instanceof FormData ? params : JSON.stringify(params);
		}

		const response = await api(config);

		// Check response status
		if (response.status >= 200 && response.status < 300) {
			// Successful response
			!!successMessage && toast.success(successMessage);
			return (response as ApiResponse<R>).data;
		} else {
			// Unsuccessful response, throw an error
			throw new Error(`Request failed with status ${response.status}`);
		}
	} catch (error: any) {
		if (error.response.status === 401 || error.response.status === 403) {
			store.dispatch(logOutAction(true));
			if (!url.includes('login')) window.location.replace('/login');
		}
		// Handle request errors
		toast.error(`Error message: ${error?.response?.data ?? error.message}`);
		throw new Error(`Request failed: ${error.message}`);
	}
};
