import {Item} from '@components/_FormElements/SizeRange/SizeRange';
/* eslint-disable no-control-regex */
const phoneNumberRegex =
	/^[+]?\d{1,4}[-. ]?[(]?\d{1,4}[)]?[-. ]?\d{1,9}[-. ]?\d{1,9}$/;
const emailRegex =
	/^(?:[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/;

export const DIGIT_REGEX = /\d/;
export const SPECIAL_CHAR_REGEX = /[!@#$%^&*(),.?":{}|<>]/;
export const UPPER_REGEX = /[A-Z]/;

const onlyDigitRegex = /^-?\d+$/;
export const sizeValidation = (data: Item[], newData: Item) => {
	let errorMessage = '';

	return errorMessage;
};

const validation = (
	inputTouched: boolean,
	inputValue: string | boolean | File | undefined | number | any[],
	inputName: string,
	required: boolean
): string => {
	if ((!required && !!!inputValue) || !inputTouched) return '';

	if (inputName === 'cabin') {
		return inputValue !== undefined ? '' : 'Pole nie może zostać puste';
	}

	if (typeof inputValue === 'string') {
		switch (inputName) {
			case 'userPassword':
				return '';
			case 'name':
			case 'company':
			case 'chassis':
				return inputValue.trim().length < 2 ? 'Pole musi przekraczać 2 znaki.' : '';
			case 'message':
			case 'description':
				return inputValue.trim().length < 10
					? 'Wiadomość musi przekraczać 10 znaków.'
					: '';
			case 'password':
				return !(
					UPPER_REGEX.test(inputValue) &&
					SPECIAL_CHAR_REGEX.test(inputValue) &&
					DIGIT_REGEX.test(inputValue) &&
					inputValue.trim().length >= 8
				)
					? 'Hasło jest za słabe'
					: '';
			case 'email':
				if (!emailRegex.test(inputValue))
					return 'Podany e-mail jest nieprawidłowy.';
				return '';
			case 'phone':
				if (!phoneNumberRegex.test(inputValue))
					return 'Numer jest za nieprawidłowy.';
				return '';
			case 'price':
			case 'weight':
			case 'maxValue':
			case 'minValue':
			case 'priority':
				return onlyDigitRegex.test(inputValue)
					? ''
					: 'Pole może zawierać tylko liczby';
			case 'minLength':
			case 'maxLength':
			case 'minWidth':
			case 'maxWidth':
			case 'minHeigth':
			case 'maxHeigth':
			case 'shortDescription':
				return '';
			case 'category':
				return inputValue !== '0' ? '' : 'Wybierz kategorię';
			case 'type':
				return inputValue !== '0' ? '' : 'Wybierz typ zgłoszenia';
			default:
				return 'Wartość jest niepoprawna';
		}
	}

	if (typeof inputValue === 'number') {
		switch (inputName) {
			case 'category':
				return inputValue !== 0 ? '' : 'Wybierz kategorię';
			case 'brand':
				return inputValue !== undefined ? '' : 'Pole nie może zostać puste';
			default:
				return '';
		}
	}

	if (inputName === 'file') {
		if (!required && !!!inputValue) return '';
		return !!inputValue
			? (inputValue as File).size / 1024 > 500
				? "Maksymalna wielkość zdjęcia to 500KB. Skorzystaj z narzędzia optymalizującego (<a href='https://tinyjpg.com/' target='_blank' rel='noopener'>https://tinyjpg.com/</a>)."
				: ''
			: 'Zdjęcie jest wymagane.';
	}

	if (typeof inputValue === 'boolean' && required)
		return inputValue === true ? '' : 'Pole jest wymagane.';

	if (typeof inputValue === 'object' && required) {
		switch (inputName) {
			case 'models':
			case 'bodies':
			case 'vehicles':
				if (!!!(inputValue as number[]).length)
					return 'Wybierz conajmniej jeden model.';
				return '';
			case 'lenghts':
			case 'widths':
			case 'heights':
				let error = '';
				(inputValue as Item[]).some((data) => {
					if (!!!data.price && +data.price !== 0) {
						error = 'Cena jest wartością wymaganą.';
						return true;
					}
					if (!!!data.weight && +data.weight !== 0) {
						error = 'Waga jest wartością wymaganą.';
						return true;
					}

					return !!!error
						? (inputValue as Item[]).some((rowData: Item) => {
								if (rowData.id === data.id) return false;

								return false;
						  })
						: true;
				});

				return error;
			default:
				return 'Wartość jest niepoprawna';
		}
	}

	return required ? 'Nieudana walidacja.' : '';
};

export default validation;
