import {requestCreator} from '@helpers/requsetCreator';
import {FullOfferModel, GetOfferResponse} from './models/offer';
import {setOrderedConfigs} from '@actions/orderedConfigsPageActions';
import {Dispatch} from 'react';
import {UnknownAction} from '@reduxjs/toolkit';
import {getFilterParams} from '@helpers/getFilterParams';

export const getOffersRequest = async (
	dispatch: Dispatch<UnknownAction>,
	pageIndex: number,
	pageSize: number,
	filters?: {
		brandIds?: number;
		bodyTypeIds?: number;
		searchValue?: string;
		startDate?: string;
		endDate?: string;
	}
): Promise<GetOfferResponse> => {
	let url = `/offer?pageIndex=${pageIndex}&pageSize=${pageSize}`;

	const res = await requestCreator<undefined, GetOfferResponse>(
		'GET',
		getFilterParams(
			{
				...filters,
				startDate: filters?.startDate
					? new Date(filters?.startDate).toJSON()
					: '',
				endDate: filters?.endDate ? new Date(filters?.endDate).toJSON() : '',
			},
			url
		)
	);

	dispatch(setOrderedConfigs(res));

	return res;
};

export const getOfferDetailsRequest = async (
	id: string
): Promise<FullOfferModel> =>
	await requestCreator<undefined, FullOfferModel>('GET', `/offer/details/${id}`);
