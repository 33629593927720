import {GetUsersResponse, UserModel} from '@api/models/authModels';

export interface UsersPage {
	users: UserModel[] | null;
	currentPage: number;
	maxPage: number;
}

export enum UsersPageActions {
	SET_USERS = 'SET_USERS',
	REMOVE_USER = 'REMOVE_USER',
	CONFIRM_USER = 'CONFIRM_USER',
	SET_USERS_CURRENT_PAGE = 'SET_USERS_CURRENT_PAGE',
	SET_USERS_MAX_PAGE = 'SET_USERS_MAX_PAGE',
}

const setUsers = (users: GetUsersResponse) => ({
	type: UsersPageActions.SET_USERS,
	payload: users,
});

const confirmUser = (userId: string) => ({
	type: UsersPageActions.CONFIRM_USER,
	payload: userId,
});

const removeUser = (userId: string) => ({
	type: UsersPageActions.REMOVE_USER,
	payload: userId,
});

const setTabsCurrentPage = (currentPage: number) => ({
	type: UsersPageActions.SET_USERS_CURRENT_PAGE,
	payload: currentPage,
});

const setTabsMaxPage = (maxPage: number) => ({
	type: UsersPageActions.SET_USERS_MAX_PAGE,
	payload: maxPage,
});

export {setUsers, removeUser, setTabsCurrentPage, setTabsMaxPage, confirmUser};
