// Libraries
import {FormEvent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
// Componetns
import Select from '@components/_FormElements/Select/Select';
import Input, {defaultInput} from '@components/_FormElements/Input/Input';
import Button, {ButtonVariant} from '@components/Button/Button';
// Helpers
import {validateForm} from '@helpers/validateForm';
// Api
import {getBodiesRequset} from '@api/body';
import {addTabRequest, editTabRequest} from '@api/tabs';
// Redux
import {getBodyPageBodies} from '@selectors/bodyPageSelectors';
import {setBodies} from '@actions/bodyPageActions';
import {getTabsPageTabs} from '@selectors/tabsPageSelectors';
import {addTab, editTab} from '@actions/tabsPageActions';
// Types
import {FormField, FormKeys} from '@commonTypes/main';
// Styles
import styles from './AddForm.module.scss';

interface AddFormProps {
	id?: number;
	closeHandler: () => void;
}

const initialState = {
	name: {...defaultInput, required: true},
	bodies: {
		...defaultInput,
		required: true,
		value: [] as number[],
	},
	priority: {...defaultInput, value: '0', required: true},
};

const AddForm = (props: AddFormProps) => {
	const {id, closeHandler} = props;

	const dispatch = useDispatch();

	const [form, setForm] = useState<FormKeys<typeof initialState>>(initialState);

	const tabs = useSelector(getTabsPageTabs);
	const bodies = useSelector(getBodyPageBodies);

	const updateFormHandler = (name: string, value: FormField) => {
		setForm({...form, [name]: value});
	};

	const submitHandler = async (e: FormEvent) => {
		e.preventDefault();
		const isError = validateForm(form, setForm);

		if (isError) return;

		const currentTab = tabs?.find((tab) => tab.id === id);

		if (id === undefined) {
			await addTabRequest(
				form.name.value,
				form.bodies.value,
				+form.priority.value
			).then((res) => {
				dispatch(addTab(res));
				closeHandler();
			});
		} else {
			if (!!!currentTab) return toast.warning('Tab not found.');
			await editTabRequest(
				id,
				form.name.value,
				form.bodies.value,
				+form.priority.value
			).then((res) => {
				dispatch(editTab(res));
				closeHandler();
			});
		}
	};

	useEffect(() => {
		if (!!!bodies?.length) {
			(async () =>
				await getBodiesRequset().then((res) => dispatch(setBodies(res))))();
		}
		if (id === undefined || bodies === undefined) return;

		const currentTab = tabs?.find((tab) => tab.id === id);

		if (!!currentTab) {
			(async () => {
				setForm({
					...form,
					name: {...form.name, value: currentTab.name},
					bodies: {...form.name, value: currentTab.carBodyTypes.map(({id}) => id)},
					priority: {...form.name, value: `${currentTab.priority}`},
				});
			})();
		} else {
			toast.warning('Tab not found.');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id, bodies]);

	return (
		<form onSubmit={submitHandler}>
			<Input
				id='name'
				name='name'
				type='text'
				placeholder={'Wpisz nazwę'}
				label={'Nazwa zakładki'}
				required={form.name.required}
				value={form.name.value}
				errorMessage={form.name.errorMessage}
				valueChangeHandler={updateFormHandler}
			/>
			<Select<number | undefined>
				id='bodies'
				name='bodies'
				label='Rodzaj zabudów'
				value={form.bodies.value}
				errorMessage={form.bodies.errorMessage}
				valueChangeHandler={updateFormHandler}
				defaultOption={{value: 0, label: 'Wybierz zabudowy'}}
				options={bodies?.map((body) => ({
					value: body.id,
					label: body.name,
				}))}
				required
				multiselect
			/>
			<Input
				id='priority'
				name='priority'
				type='text'
				placeholder={'Wpisz priorytet'}
				label={'Priorytet'}
				required={form.priority.required}
				value={form.priority.value}
				errorMessage={form.priority.errorMessage}
				valueChangeHandler={updateFormHandler}
			/>

			<div className={styles.buttons}>
				<Button
					type='button'
					variant={ButtonVariant.Outline}
					onClick={closeHandler}>
					Anuluj
				</Button>
				<Button type='submit' variant={ButtonVariant.Primary}>
					Zapisz
				</Button>
			</div>
		</form>
	);
};

export default AddForm;
