import {FormEvent, useCallback, useEffect, useMemo, useState} from 'react';
import VehicleAddFormBrand from '../VehicleAddFormBrand/VehicleAddFormBrand';
import {defaultInput} from '@components/_FormElements/Input/Input';
import {FormField, FormKeys} from '@commonTypes/main';
import {validateForm} from '@helpers/validateForm';
import VehicleAddFormModel from '../VehicleAddFormModel/VehicleAddFormModel';
import VehicleAddFromCabin from '../VehicleAddFromCabin/VehicleAddFromCabin';
import VehicleAddFormBody from '../VehicleAddFormBody/VehicleAddFormBody';
import {v4} from 'uuid';

import styles from './VehicleAddForm.module.scss';
import VehicleAddFormChassis from '../VehicleAddFormChassis/VehicleAddFormChassis';
import VehicleAddFormSizes from '../VehicleAddFormSizes/VehicleAddFormSizes';
import Button from '@components/Button/Button';
import {
	addVehicleRequest,
	editVehicleRequest,
	getVehicleRequest,
	vehicleBodyImageRequest,
	vehicleImageRequest,
} from '@api/vehicles';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {getChassisRequset} from '@api/chassis';
import {ChassisModel} from '@api/models/chassis';
import {Height, Size} from '@api/models/vehiclesModels';
import {toast} from 'react-toastify';
import VehicleAddFormDescription from '../VehicleAddFormDescription/VehicleAddFormDescription';
import {getFileFromUrl} from '@helpers/getFileFromUrl';
import {useDispatch} from 'react-redux';
import {loadingAction} from '@actions/appActions';

const DEFAULT = {
	...defaultInput,
	required: true,
	value: undefined as undefined | number,
};
const DEFAULT_STR = {
	...defaultInput,
	required: true,
	value: '' as string,
};

const defaultSizes = {
	widths: {
		...defaultInput,
		required: true,
		value: [{id: '0', value: '', price: '', weight: ''}] as Size[],
	},
	lenghts: {
		...defaultInput,
		required: true,
		value: [{id: '0', value: '', price: '', weight: ''}] as Size[],
	},
	heights: {
		...defaultInput,
		required: true,
		value: [
			{id: '0', value: '', price: '', weight: '', spoilerOptionId: 0},
		] as Height[],
	},
};

const DEFAULT_ID = {
	...defaultInput,
	value: undefined as undefined | number,
};

export const initialState = {
	id: DEFAULT_ID,
	brand: DEFAULT,
	model: DEFAULT,
	body: DEFAULT,
	cabin: DEFAULT,
	chassis: DEFAULT_STR,
	...defaultSizes,
	price: defaultInput,
	weight: defaultInput,
	description: DEFAULT_STR,
	image: {
		...defaultInput,
		value: undefined as undefined | File,
		required: false,
	},
	bodyImage: {
		...defaultInput,
		value: undefined as undefined | File,
		required: false,
	},
	isHidden: {...defaultInput, value: true, required: false},
};

export type DetailsFn = (
	newValues: {
		name: 'widths' | 'lenghts' | 'heights';
		value: Size[] | Height[];
	}[]
) => void;

const VehicleAddForm = () => {
	const [form, setForm] = useState<FormKeys<typeof initialState>>(initialState);
	const [allChassis, setAllChassis] = useState<ChassisModel[]>([]);
	const [showSizes, setShowSizes] = useState<boolean>(true);

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	useEffect(() => {
		(async () => {
			let newFormData = {} as FormKeys<typeof initialState>;
			searchParams.forEach(async (value, key) => {
				if (key === 'vehicleId') {
					newFormData.id = {...DEFAULT, value: +value};
				} else if (key === 'brand' || key === 'model') {
					newFormData[key] = {...DEFAULT, value: +value};
				}
			});

			if (!!newFormData.id?.value) {
				await getVehicleRequest(+newFormData.id.value).then(async (res) => {
					const image = await getFileFromUrl(res[0].vechicleImg, res[0].brand);
					const bodyImage = res[0].vechicleBodyImg
						? await getFileFromUrl(res[0].vechicleBodyImg, res[0].brand)
						: undefined;

					const isSizesHidden =
						res[0].vechicleAvailableLenghts.length === 1 &&
						+res[0].vechicleAvailableLenghts[0].value === 0 &&
						res[0].vechicleAvailableWidths.length === 1 &&
						+res[0].vechicleAvailableWidths[0].value === 0 &&
						res[0].vechicleAvailableHeights.length === 1 &&
						+res[0].vechicleAvailableHeights[0].value === 0;

					newFormData.brand = {...form.brand, value: res[0].brandId};
					newFormData.model = {...form.model, value: res[0].modelId};
					newFormData.body = {...form.body, value: res[0].bodyTypeId};
					newFormData.cabin = {...form.cabin, value: res[0].cabinTypeId};
					newFormData.chassis = {...form.chassis, value: res[0].chassis};
					newFormData.lenghts = {
						...form.lenghts,
						value: res[0].vechicleAvailableLenghts.map((item) => ({
							...item,
							id: v4(),
						})),
						required: !isSizesHidden,
					};
					newFormData.widths = {
						...form.widths,
						value: res[0].vechicleAvailableWidths.map((item) => ({
							...item,
							id: v4(),
						})),
						required: !isSizesHidden,
					};
					newFormData.heights = {
						...form.heights,
						value: res[0].vechicleAvailableHeights.map((item) => ({
							...item,
							id: v4(),
						})),
						required: !isSizesHidden,
					};
					newFormData.price = {
						...form.price,
						value: res[0].vechicleAvailableLenghts[0].price,
						required: isSizesHidden,
					};
					newFormData.weight = {
						...form.weight,
						value: res[0].vechicleAvailableLenghts[0].weight,
						required: isSizesHidden,
					};
					newFormData.description = {
						...form.description,
						value: res[0].description,
					};
					newFormData.isHidden = {
						...form.isHidden,
						value: res[0].isHidden,
					};
					newFormData.image = {
						...form.image,
						value: image,
					};
					newFormData.bodyImage = {
						...form.bodyImage,
						value: bodyImage,
					};
					setShowSizes(!isSizesHidden);
				});
			}

			setForm({...initialState, ...newFormData});
		})();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchParams]);

	const updateFormHandler = (name: string, value: FormField) => {
		switch (name) {
			case 'brand':
				setForm({
					...initialState,
					[name]: value,
					id: DEFAULT_ID,
					body: DEFAULT,
					model: DEFAULT,
					cabin: DEFAULT,
					chassis: DEFAULT_STR,
				});
				break;
			case 'model':
				setForm({
					...form,
					[name]: value,
					id: DEFAULT_ID,
					body: DEFAULT,
					cabin: DEFAULT,
					chassis: DEFAULT_STR,
				});
				break;
			case 'body':
				setForm({
					...form,
					[name]: value,
					id: DEFAULT_ID,
					cabin: DEFAULT,
					chassis: DEFAULT_STR,
				});
				break;
			case 'cabin':
				setForm({...form, [name]: value, id: DEFAULT_ID, chassis: DEFAULT_STR});
				break;
			case 'chassis':
				const chassisExist: ChassisModel | undefined = allChassis?.find(
					(item) => item.name === value.value
				);

				setForm({
					...form,
					[name]: value,
					id: {
						value: chassisExist?.carCabinBodyworkId,
						errorMessage: '',
						isValid: true,
						required: false,
					},
				});
				break;
			default:
				setForm({...form, [name]: value});
				break;
		}
	};

	const submitHandler = async (e: FormEvent) => {
		e.preventDefault();
		const isError = validateForm(form, setForm);

		if (isError) return toast.warning('Nie można zapisać pojazdu.');

		dispatch(loadingAction(true));

		const data = {
			modelId: form.model.value!,
			cabinId: form.cabin.value!,
			bodyTypeId: form.body.value!,
			chassis: form.chassis.value!,
			lenghts: showSizes
				? form.lenghts.value
				: [
						{
							id: '0',
							value: '0',
							price: form.price.value,
							weight: form.weight.value,
						},
				  ],
			widths: showSizes
				? form.widths.value
				: [{id: '0', value: '0', price: '0', weight: '0'}],
			heights: showSizes
				? form.heights.value
				: [
						{
							id: '0',
							value: '0',
							price: '0',
							weight: '0',
							spoilerOptionId: 0,
						},
				  ],
			description: form.description.value,
			isHidden: form.isHidden.value,
		};

		if (form.id.value === undefined) {
			await addVehicleRequest(data).then(async (res) => {
				await vehicleImageRequest(res[0].id, {
					image: form.image.value ?? null,
				}).then(async () => {
					await vehicleBodyImageRequest(res[0].id, {
						image: form.bodyImage.value ?? null,
					}).then(() => {
						dispatch(loadingAction(false))
						navigate('/vehicles');
					});
				});
			});
		} else {
			await editVehicleRequest(form.id.value, data).then(async (res) => {
				await vehicleImageRequest(res[0].id, {
					image: form.image.value ?? null,
				}).then(async () => {
					await vehicleBodyImageRequest(res[0].id, {
						image: form.bodyImage.value ?? null,
					}).then(() => {
						dispatch(loadingAction(false))
						navigate('/vehicles');
					});
				});
			});
		}
	};

	const step2Unlocked = useMemo(() => !!form.brand.value, [form.brand.value]);

	const step3Unlocked = useMemo(() => !!form.model.value, [form.model.value]);

	const step4Unlocked = useMemo(() => !!form.body.value, [form.body.value]);

	const step5Unlocked = useMemo(() => {
		const modelId = form.model.value;
		const cabinTypeId = form.cabin.value;
		const bodyTypeId = form.body.value;

		if (!!modelId && !!bodyTypeId && (!!cabinTypeId || cabinTypeId === null)) {
			(async () => {
				await getChassisRequset({modelId, bodyTypeId, cabinTypeId}).then((res) => {
					setAllChassis(res);
				});
			})();
		}

		return !!form.cabin.value || cabinTypeId === null;
	}, [form.cabin.value, form.body.value, form.model.value]);

	const step5ValuesUnlocked = useMemo(
		() => !!form.chassis.value,
		[form.chassis.value]
	);

	const step6ValuesUnlocked = useMemo(
		() =>
			step5ValuesUnlocked &&
			!!form.lenghts.value &&
			!!form.widths.value &&
			!!form.heights.value,
		[
			form.heights.value,
			form.lenghts.value,
			form.widths.value,
			step5ValuesUnlocked,
		]
	);

	const submitUnlocked = useMemo(
		() => step6ValuesUnlocked && !!form.description.value,
		[form.description.value, step6ValuesUnlocked]
	);

	const updateDetailsHandler: DetailsFn = (newValues) => {
		const newForm = {} as FormKeys<typeof initialState>;
		newValues.map(
			(item) =>
				(newForm[item.name] = {...form[item.name], value: item.value as any[]})
		);
		setForm({...form, ...newForm});
	};

	const toggleShowSizes = useCallback(() => {
		const toggledValue = !showSizes;

		setForm({
			...form,
			weight: {...form.weight, required: !toggledValue},
			price: {...form.weight, required: !toggledValue},
			widths: {...form.widths, required: toggledValue},
			lenghts: {...form.lenghts, required: toggledValue},
			heights: {...form.heights, required: toggledValue},
		});

		setShowSizes(toggledValue);
	}, [form, showSizes]);

	return (
		<form className={styles.wrapper} onSubmit={submitHandler}>
			<VehicleAddFormBrand
				currentBrand={form.brand.value}
				updateFormHandler={updateFormHandler}
			/>
			{step2Unlocked && form.brand.value && (
				<VehicleAddFormModel
					brandId={form.brand.value}
					currentModel={form.model.value}
					updateFormHandler={updateFormHandler}
				/>
			)}
			{step3Unlocked && (
				<VehicleAddFormBody
					currentBody={form.body.value}
					updateFormHandler={updateFormHandler}
				/>
			)}
			{step4Unlocked && (
				<VehicleAddFromCabin
					modelId={form.model.value}
					bodyId={form.body.value}
					currentCabin={form.cabin.value}
					updateFormHandler={updateFormHandler}
				/>
			)}
			{step5Unlocked && (
				<VehicleAddFormChassis
					currentChassis={form.chassis.value}
					allChassis={allChassis}
					updateFormHandler={updateFormHandler}
				/>
			)}
			{step5ValuesUnlocked && (
				<VehicleAddFormSizes
					form={form}
					chassis={form.chassis.value}
					updateDetailsHandler={updateDetailsHandler}
					updateFormHandler={updateFormHandler}
					showSizes={showSizes}
					toggleShowSizes={toggleShowSizes}
				/>
			)}
			{step6ValuesUnlocked && (
				<VehicleAddFormDescription
					form={form}
					updateFormHandler={updateFormHandler}
				/>
			)}
			{submitUnlocked && (
				<Button classes={styles.submit} type='submit'>
					Zapisz
				</Button>
			)}
		</form>
	);
};

export default VehicleAddForm;
